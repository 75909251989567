<template>
  <section>
    <b-card>
      <b-row>
        <b-col cols="12" md="6">
          <div>
            <validation-observer ref="newUserRoleForm" #default="{invalid}">
              <b-form ref="form" @submit.prevent="onCreateOrUpdateUserRole">

                <div class="mb-2">
                  <b-form-group
                      label="Title"
                      label-for="title"
                      label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="title"
                        :rules="`required`"
                    >
                      <b-form-input
                          id="title"
                          v-model="userRole.title"
                          placeholder="Enter resource title"
                          :state="errors.length > 0 ? false : null"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div class="mb-2">
                  <b-form-group
                      label="Description"
                      label-for="description"
                      label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="description"
                        vid="description"
                        rules="required"
                    >
                      <b-form-textarea
                          id="Description"
                          v-model="userRole.description"
                          placeholder="Provide a brief description for the resource"
                          :state="errors.length > 0 ? false : null"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div class="mb-2">
                  <b-form-group
                      label="Policy Ids"
                      label-for="policy_ids"
                      label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="policy_ids"
                        vid="policy_ids"
                        rules="required"
                    >
                      <v-select
                          v-model="userRole.policy_ids"
                          :options="policies"
                          :close-on-select="false"
                          :reduce="val => val.id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          multiple
                          clearable
                          label="name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div>
                  <b-button
                      type="submit"
                      block
                      variant="primary"
                      :disabled="invalid"
                  >
                    {{ isUpdate ? 'Update User Role' : 'Create User Role' }}

                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import { get } from "lodash";
import vSelect from 'vue-select'
import { required } from "@core/utils/validations/validations";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { BButton, BCard, BForm, BRow, BCol, BFormInput, BFormGroup, BFormTextarea } from "bootstrap-vue";

const rawUserRole = {
  title: "",
  description: "",
  policy_ids: []
}

const watchUpdateHandler = {
  handler(update) {
    if (update) {
      this.userRole = get(update, 'data');
    }
  },
  deep: true,
  immediate: true
}

export default {
  name: "AddUpdateUserRole",
  components: {
    BCard,
    BCol,
    BRow,
    vSelect,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    updatingUserRole: {
      type: [Object, null, undefined],
      default: null,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      searchFilter: "",
      policies: [{}],
      userRole: { ...rawUserRole },
      required
    }
  },
  computed: {
    isUpdate() {
      return !!this.updatingUserRole
    }
  },
  watch: {
    updatingUserRole: watchUpdateHandler
  },
  async created() {
    await this.fetchPolicies();
  },
  methods: {
    async onCreateOrUpdateUserRole() {
      try {
        this.loading = true;
        const success = await this.$refs.newUserRoleForm.validate();
        if (!success){
          return;
        }
        const payload = {
          title: this.userRole.title,
          description: this.userRole.description,
          policy_ids: this.userRole.policy_ids
        }
        if (this.isUpdate){
          this.$emit("update", payload);
        } else {
          this.$emit("create", payload);
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async fetchPolicies() {
      try {
        this.loading = true;

        const {
          currentPage: page,
          searchFilter: search,
        } = this;

        const query = {
          limit: 1000,
          page,
          search
        };

        const request = await this.useJwt().adminService.fetchAdminPolicies(query);
        const { data } = request.data;

        this.policies = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
        return [];
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    }
  }
}
</script>

<style scoped>

</style>
