<template>
  <b-overlay :show="loading">
    <add-update-user-role
        ref="newUserRoleForm"
        :updating-user-role="userRole"
        @update="onUpdateUserRole"
    />
  </b-overlay>
</template>

<script>
import store from "@/store";
import { MUTATE_USER_DATA } from "@/store/config/mutation-keys";
import { get } from "lodash";
import { BOverlay } from "bootstrap-vue";
import AddUpdateUserRole from "@/pages/administrations/user-roles/add-update-user-role.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "UpdateUserRole",
  components: {
    AddUpdateUserRole,
    BOverlay,
  },
  data() {
    return {
      loading: true,
      userRole: null,
      userRoleId: null
    }
  },
  computed: {
    userData() {
      return this.$store.getters[`auth/userData`];
    },
  },
  async created() {
    await this.getUserRole();
  },
  methods: {
    async getUserRole() {
      try {
        this.loading = true;
        const { id } = this.$route.params;

        this.userRoleId = id;
        const { data } = await this.useJwt()
            .adminService
            .getUserRole(id);
        this.userRole = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onUpdateUserRole(payload) {
      try {
        this.loading = false;
        await this.useJwt()
            .adminService
            .updateUserRole(this.userRoleId, payload);

        await this.updateUserPermissions();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `User role updated successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async updateUserPermissions() {
      const user_id = this.getValueFromSource(this.userData, 'id')

      const { data: { data: user } } = await this.useJwt().adminService.fetchAdmin(user_id);
      store.commit(`auth/${MUTATE_USER_DATA}`, user);
    }
  }
}
</script>

<style scoped>

</style>
