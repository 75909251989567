var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',[_c('validation-observer',{ref:"newUserRoleForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onCreateOrUpdateUserRole.apply(null, arguments)}}},[_c('div',{staticClass:"mb-2"},[_c('b-form-group',{attrs:{"label":"Title","label-for":"title","label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":""}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","placeholder":"Enter resource title","state":errors.length > 0 ? false : null},model:{value:(_vm.userRole.title),callback:function ($$v) {_vm.$set(_vm.userRole, "title", $$v)},expression:"userRole.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-2"},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description","label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":""}},[_c('validation-provider',{attrs:{"name":"description","vid":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"Description","placeholder":"Provide a brief description for the resource","state":errors.length > 0 ? false : null},model:{value:(_vm.userRole.description),callback:function ($$v) {_vm.$set(_vm.userRole, "description", $$v)},expression:"userRole.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-2"},[_c('b-form-group',{attrs:{"label":"Policy Ids","label-for":"policy_ids","label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":""}},[_c('validation-provider',{attrs:{"name":"policy_ids","vid":"policy_ids","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.policies,"close-on-select":false,"reduce":function (val) { return val.id; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","clearable":"","label":"name"},model:{value:(_vm.userRole.policy_ids),callback:function ($$v) {_vm.$set(_vm.userRole, "policy_ids", $$v)},expression:"userRole.policy_ids"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',[_c('b-button',{attrs:{"type":"submit","block":"","variant":"primary","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.isUpdate ? 'Update User Role' : 'Create User Role')+" ")])],1)])]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }